<template>
	<div class="goodsExport">
		<el-button type="primary" style="width:90px;" @click="goodsExportFun">商品导入</el-button>

		<!-- 表格 -->
		<div class="table-container" style="margin-top:30px;" v-loading="productLoading">
			<el-table :data="groupData" v-loading="loading">
				<el-table-column prop="AddTime" label="操作时间"></el-table-column>
				<el-table-column prop="EmployeeName" label="操作人"></el-table-column>
				<el-table-column prop="ProductCount" label="商品总数"></el-table-column>
				<el-table-column prop="SuccessProductCount" label="成功导入数量"></el-table-column>				
				<el-table-column prop="ImportRecordStateName" label="状态"></el-table-column>
				<el-table-column label="操作" width="140">
					<template slot-scope="scope">
						<span style="cursor: pointer;" v-if="scope.row.ImportRecordStateName=='导入中'">报表生成中</span>
						<span style="color:#409EFF;cursor: pointer;" v-if="scope.row.ImportRecordStateName=='已完成'" @click="downloadFail(scope.row)">下载失败数据</span>
					</template>
				</el-table-column>
			</el-table>
			<div style="display:flex;justify-content:space-between;align-items:center;">
				<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
				</div>
				<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total">
				</el-pagination>
			</div>
		</div>

		<!-- 商品导入的弹框 -->
		<el-dialog :visible.sync="productVisable" width="800px" title="商品导入">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
				<el-form-item label="商品运费:">
					<el-radio-group v-model="ruleForm.FreightType">
						<el-form-item label-width='0' :prop="ruleForm.FreightType===0?'UniformFreight':'nocheck'">
							<el-radio :label="0" @click.native.prevent="clickitem(0)">全国统一运费</el-radio>

							<span style="margin-left:-26px;">
								<el-input v-model="ruleForm.UniformFreight" style="width:250px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
								元
							</span>
						</el-form-item>
						<el-form-item label-width='0' :prop="ruleForm.FreightType===1?'FreightTemplateId':'nocheck'">
							<el-radio :label="1" @click.native.prevent="clickitem(1)" style="margin-top:15px">使用运费模板
								<el-select v-model="ruleForm.FreightTemplateId" style="width:250px;" placeholder="请选择运费模板" filterable clearable>
									<el-option v-for="(item,index) in FreightTemplateIdList " :key="item.Id" :value="item.Id" :label="item.TemplateName"></el-option>
								</el-select>
							</el-radio>
						</el-form-item>
					</el-radio-group>
					<div style="font-size: 14x;color: #82848A;margin-top: 15px;">若导入的商品中，包含需要开启快递发货的商品，请先在此处选择对应的运费信息</div>
				</el-form-item>

				<el-form-item label="上传数据:">
					<form name="uploadForm" method="post">
						<input type="file" ref="upFiles" id="inputsId">
					</form>
					<div style="font-size: 14x;color: #82848A;margin-top: 5px;">
						商品导入功能，即创建新商品，暂不支持对商城内已有商品的价格、库存等进行修改；暂不支持导入组合商品。请
						<span style="color:#2d8cf0;cursor: pointer;" @click="downLoadsend">下载商品导入模块</span>
					</div>
				</el-form-item>

				<div style="text-align: center;margin-top:30px">
					<el-button type="primary" style="width: 150px;" @click="handleInportMember('ruleForm')" :loading="sendorderLoading">确认导入</el-button>
				</div>

			</el-form>

		</el-dialog>

	</div>
</template>

<script>
	import {
		freightTemplateList,
		productImportRecordList
	} from '@/api/goods'
	import config from '@/config/index'
	export default {
		data() {
			var checkno = (rule, value, callback) => {
				return callback();
			};
			var checkUniformFreight = (rule, value, callback) => {
				console.log(value == 100000000, 999)
				if ((0 < value && value <= 100000000) || value === 0 || value === '0') {
					return callback();
				} else if (value > 100000000) {
					return callback(new Error('数值超出限制，请重新设置'));
				} else {
					return callback(new Error('请设置商品运费'));
				}
			};
			var checkFreightTemplateId = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请选择运费模板'));
				} else {
					return callback();
				}
			};
			return {
				groupData: [],
				loading: false,
				currentPage: 1,
				pageSize: 20,
				Total: 1,
				productVisable: false,
				FreightTemplateIdList: [],
				ruleForm: {
					FreightType: '',
					UniformFreight: null,
					FreightTemplateId: '',
				},
				rules: {
					UniformFreight: [{
						validator: checkUniformFreight,
						trigger: 'change'
					}],
					FreightTemplateId: [{
						validator: checkFreightTemplateId,
						trigger: 'change'
					}],
					nocheck: [{
						validator: checkno,
						trigger: 'change'
					}],
				},
				sendorderLoading: false,
				exportUrl: config.EXPORT_URL,
				productLoading: false


			}
		},
		beforeMount() {
			//获取列表数据
			this.getInfor()
			this.getList()
		},
		methods: {
			clickitem(record) {
				console.log(record, 999)
				if (record === this.ruleForm.FreightType) {
					this.ruleForm.FreightType = ''
				} else {
					this.ruleForm.FreightType = record
				}
			},

			// 获取列表数据
			async getList() {
				try {
					this.productLoading = true
					let data = {
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据

					}
					let result = await productImportRecordList(data);
					this.Total = result.Result.Total;
					this.groupData = result.Result.Results || [];

				} catch (e) {
					console.log(e)
				} finally {
					this.productLoading = false
				}

			},
			//筛选数据
			async getInfor() {
				try {
					// 获取运费模板数据
					let data = {
						OrderBy: 'Id',
						IsAsc: true
					}
					let result3 = await freightTemplateList(data)
					this.FreightTemplateIdList = result3.Result
				} catch (e) {
					console.log(e)
				} finally {

				}

			},
			// 下载模板
			downLoadsend() {
				let url = this.exportUrl + '/pc/product/importTemplate'
				window.open(url)
			},
			// 确认上传
			async handleInportMember(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						let that = this;
						const loading = that.$loading({
							lock: true,
							text: 'Loading',
							spinner: 'el-icon-loading',
							// background: 'rgba(0, 0, 0, 0.7)'
						});

						try {
							setTimeout(() => {
								var formData = new FormData();
								formData.append('file', that.$refs['upFiles'].files[0])
								// console.log(formData)
								formData.append('FreightType', this.ruleForm.FreightType);
								formData.append('FreightTemplateId', this.ruleForm.FreightTemplateId);
								formData.append('UniformFreight', this.ruleForm.UniformFreight||'');
								// +'&FreightType=' +this.ruleForm.FreightType +'&FreightTemplateId=' + this.ruleForm.FreightTemplateId +'&UniformFreight=' + this.ruleForm.UniformFreight
								$.ajax({
									// url: this.exportUrl + '/pc/product/import?FreightType='+this.ruleForm.FreightType+"&FreightTemplateId="+this.ruleForm.FreightTemplateId+'&UniformFreight='+this.ruleForm.UniformFreight,
									url: this.exportUrl + '/pc/product/import',
									timeout: 20000, //超时时间设置，单位毫秒
									type: 'POST',
									data: formData,
									async: false,
									cache: false,
									contentType: false,
									xhrFields: {
										withCredentials: true
									},
									processData: false,
									success: function(returndata) {
										if (returndata.IsSuccess) {
											that.$message({
												message: '上传成功',
												type: 'success'
											});
											that.productVisable = false;
											loading.close();
											setTimeout(() => {
												that.getList()
											}, 200)
										} else {
											loading.close();
											that.$message({
												message: returndata.Message,
												type: 'error'
											});
										}
									},
									complete: function(XMLHttpRequest, status) { //请求完成后最终执行参数
										if (status == 'timeout') { //超时,status还有success,error等值的情况
											ajaxTimeoutTest.abort();
											alert("超时");
										}
									},
									error: function(returndata) {
										loading.close();
										that.$message({
											message: returndata.Message,
											type: 'error'
										});
										// alert("文件上传失败");
									}
								});
							}, 500)


						} catch (error) {
							loading.close();
							console.log(error);
						} finally {

						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});

			},
			// 下载失败数据
			downloadFail(record) {
				let url = this.exportUrl + '/pc/productImportRecord/exportError?' +
					'&ProductImportRecordId=' + record.ProductImportRecordId
				window.open(url)
			},
			// 商品导入
			goodsExportFun() {
				this.ruleForm.FreightType = ''
				this.ruleForm.UniformFreight = null
				this.ruleForm.FreightTemplateId = ''
				this.$nextTick(() => {
					if (this.$refs.upFiles) {
						this.$refs.upFiles.value = '';
					}

				});
				this.productVisable = true;
			},
			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getList();
			},

		}
	}
</script>

<style lang="less" scoped>
	.goodsExport {
		padding: 10px;
		background: #fff;
	}
</style>
